import axios from "axios";
import qs from "qs";
import * as Sentry from "@sentry/browser";
const Cookies = require("js-cookie");

if (process.env.NODE_ENV !== "development") {
  Sentry.init({
    dsn: "https://8a8a2ad3c70eb03b88368ee517ce175c@o4507130937671680.ingest.us.sentry.io/4507386115719168"
  });
}

export function axiosResponseInterceptor(error) {
  if (error.response === undefined) {
    if (error.message !== undefined) {
      setTimeout(() => {
        alert(
          "Could not connect to server: " +
            error.message +
            "\nTry refreshing the page."
        );
      }, 1000);
    }
  } else if (error.response.status === 401) {
    document.location = "/login/?next=" + document.location;
  } else if (error.response.status === 500) {
    let sentry_id = Cookies.get("sentry_id");
    if (sentry_id && process.env.NODE_ENV !== "development") {
      Sentry.showReportDialog({ eventId: sentry_id });
      Cookies.remove("sentry_id");
    } else {
      setTimeout(() => {
        alert("An error occurred: " + error.response.statusText);
      }, 100);
    }
  } else {
    return Promise.reject(error);
  }

  return new Promise(() => {});
}

window.FontAwesomeConfig = { searchPseudoElements: true };

export const axiosParamsSerializer = params =>
  qs.stringify(params, { indices: false });

axios.defaults.paramsSerializer = axiosParamsSerializer;
axios.interceptors.response.use(undefined, axiosResponseInterceptor);
axios.defaults.xsrfHeaderName = "X-CSRFToken";
axios.defaults.xsrfCookieName = "csrftoken";
