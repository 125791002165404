import React, { Component, Fragment } from "react";
import classNames from "classnames";
import { Link, withRouter } from "react-router-dom";
import {
  Button,
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  Container,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Row,
  Col
} from "reactstrap";
import Fa from "~/util/fa";
import { withAuth } from "~/util/api/withAuth";
import { withProfile } from "~/util/api/withProfile";
import images from "~/images";
import MultiAdd from "~/components/dashboard/MultiAdd";

const logoColumnClassNames = [
  "text-left",
  "d-flex",
  "align-items-center",
  "justify-content-start"
];
const slotColumnClassNames = ["text-left", "d-flex", "align-items-center"];

const menuColumnClassNames = [
  "text-right",
  "pr-0",
  "d-flex",
  "align-items-center"
];

const slotColSizes = {
  xs: "12",
  lg: "7"
};
const logoColSizes = {
  xs: "6",
  lg: "2"
};
const menuColSizes = {
  xs: "6",
  lg: "auto"
};
const LoginBtn = () => (
  <NavItem>
    <Link to="/login/" className="btn btn-outline-primary px-3 py-1">
      Log In
    </Link>
  </NavItem>
);

export class NavBar extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);

    /*
     * 1. If we want to refactor this component into something simpler,
     * just know the point of the different classnames is to have two
     * different sets of classNames for when there is a slot like in the case of the
     * dashboard, and when there is not like in the case of the details pages.
     * 2. It's also possible that you would want this to be functional component
     */
    let toMergeWithLogoColumnClassNames,
      toMergeWithSlotColumnClassNames,
      toMergeWithMenuColumnClassNames,
      slotColSizes = {},
      logoColSizes = {},
      menuColSizes = {};

    if (props.details) {
      toMergeWithLogoColumnClassNames = ["order-1", "order-lg-1"];
      toMergeWithSlotColumnClassNames = [
        "order-0",
        "order-lg-0",
        "justify-content-start"
      ];
      toMergeWithMenuColumnClassNames = ["order-1", "order-lg-2"];

      // search is "slotColSizes" for details pages
      slotColSizes.xs = "4";
      slotColSizes.lg = "4";

      logoColSizes.xs = "4";
      logoColSizes.lg = "2";
      menuColSizes.xs = "4";
      menuColSizes.lg = "4";
    } else {
      // search is "slotColSizes" for details pages

      toMergeWithLogoColumnClassNames = ["order-0", "order-lg-0"];
      toMergeWithSlotColumnClassNames = [
        "order-2",
        "order-lg-1",
        "justify-content-between",
        "p-0"
      ];
      toMergeWithMenuColumnClassNames = ["order-1", "order-lg-2"];
      slotColSizes.xs = "12";
      slotColSizes.lg = "7";
      logoColSizes.xs = "6";
      logoColSizes.lg = "2";
      menuColSizes.xs = "6";
      menuColSizes.lg = "3";
    }

    this.state = {
      logoColumnClassNames: [
        ...logoColumnClassNames,
        ...toMergeWithLogoColumnClassNames
      ],
      slotColumnClassNames: [
        ...slotColumnClassNames,
        ...toMergeWithSlotColumnClassNames
      ],
      menuColumnClassNames: [
        ...menuColumnClassNames,
        ...toMergeWithMenuColumnClassNames
      ],
      slotColSizes,
      logoColSizes,
      menuColSizes,

      isOpen: true
    };
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  renderLoggedInMenu() {
    const { profile } = this.props;
    return (
      <Fragment>
        <UncontrolledDropdown nav inNavbar>
          <DropdownToggle nav caret className="word-wrap">
            {!_.isEmpty(profile) ? (
              <>
                <div className="d-block d-md-none">
                  <Fa className="mr-1" icon="bars" />
                </div>

                <div className="d-none d-md-block">
                  <span>
                    <strong>Hello, {profile.name}</strong>
                  </span>{" "}
                </div>
              </>
            ) : (
              <span>Account</span>
            )}
          </DropdownToggle>

          <DropdownMenu right className="user-menu position-absolute shadow ">
            <div className="d-flex d-md-none px-4">
              <div to="/profile/">
                <strong>{profile?.name}</strong>
              </div>
            </div>
            <DropdownItem>
              <Link className="text-dark" to="/profile/">
                My Account
              </Link>
            </DropdownItem>

            {profile &&
            profile.profile_types &&
            profile.profile_types.includes("admin") ? (
              <>
                <DropdownItem id="pettit_admin_button">
                  <Link
                    className="text-dark"
                    data-test="profile-menu-admin-link"
                    to="/admin/boatyard"
                  >
                    Pettit Admin
                  </Link>
                </DropdownItem>
                <DropdownItem>
                  <a
                    className="text-dark"
                    data-test="profile-menu-service-manager-link"
                    href="/menu/"
                    onClick={e => {
                      e.preventDefault(); // Prevents the router from intercepting
                      window.location.href = "/menu/"; // Forces a full page reload

                    }}
                  >
                    Service Manager
                  </a>
                </DropdownItem>
              </>
            ) : null}
            <DropdownItem
              className="text-dark"
              onClick={this.props.logout}
              id="logout_button"
            >
              Log Out
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </Fragment>
    );
  }

  reactiveLogoClassNames() {
    return classNames(this.state.logoColumnClassNames);
  }

  reactiveSlotClassNames() {
    return classNames(this.state.slotColumnClassNames);
  }
  reactiveMenuClassNames() {
    return classNames(this.state.menuColumnClassNames);
  }

  render() {
    return (
      <div>
        <Navbar
          className={
            this.props.className + " " &&
            this.props.shadow &&
            "nav-shadow-light"
          }
        >
          <Container className=" px-0">
            <Row className="w-md-100 d-flex justify-content-between">
              <Col
                xs={this.state.logoColSizes.xs}
                lg={this.state.logoColSizes.lg}
                className={this.reactiveLogoClassNames() + " ml-0 pl-0"}
              >
                <img
                  className="boatyard-logo"
                  src={images[WL.logo]}
                  alt={WL.title}
                  style={{
                    maxWidth: "100%",
                    height: "auto",
                    maxHeight: "80px"
                  }}
                />
              </Col>
              <Col
                xs={this.state.slotColSizes.xs}
                lg={this.state.slotColSizes.lg}
                className={this.reactiveSlotClassNames()}
              >
                {/* logic to show slot/children or backbutton */}
                {this.props.children ? (
                  <div className="d-flex mr-auto ml-auto justify-content-center align-items-center">
                    {" "}
                    {this.props.children}
                  </div>
                ) : (
                  <Link to={"/"} className="d-block my-3">
                    <Fa icon="arrow-left" className="mr-2" />
                    <span className="small-uppercase ml-2">Dashboard</span>
                  </Link>
                )}
              </Col>
              <Col
                xs={this.state.menuColSizes.xs}
                lg={this.state.menuColSizes.lg}
                className={this.reactiveMenuClassNames()}
              >
                {/* <NavbarToggler onClick={this.toggle} /> */}

                <Collapse isOpen={this.state.isOpen} navbar className="h-100">
                  <Nav
                    className="ml-auto d-flex flex-row align-items-center justify-content-end pl-2 pl-md-0 navbar-nav h-100"
                    navbar
                  >
                    {!this.props.details ? <MultiAdd /> : null}

                    {this.renderLoggedInMenu()}
                  </Nav>
                </Collapse>
              </Col>
            </Row>
          </Container>
        </Navbar>
      </div>
    );
  }
}

export default withRouter(withAuth(withProfile(NavBar)));
