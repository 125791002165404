import { create } from 'zustand'
const kindsOrder = [
  "workorder",
  "boat",
  "profile",
  "facility",
  "readyforinvoice"
];
const useDashboardStore = create((set, get) => ({
  // Work Orders, Boats, Profiles, and facilities
  selectedKinds: ["workorder"],

  // Declined, Draft, Pending, Approved, Completed, Dismissed, and Changed
  workOrderStatuses: [],
  // Select quickbooks items mode on/off
  qbSelectionActive: false,
  // Whether the api is loading
  isLoading: false,
  // Search query
  query: "",

  // Selected items for quickbooks actions
  selectedItems: [],

  // Current page state
  pageState: "",
  // Array of search results
  items: [],
  // Whether the advanced search is active
  advancedSearchActive: false,
  fireSearch: 0,
  cursor: null,

  // selectedKinds should always match kindsOrder array order (workorder, boat, profile, facility, readyforinvoice)
  setSelectedKinds: kinds => {
    const newKinds = kindsOrder
      .map(kind => {
        if (kinds.includes(kind)) {
          return kind;
        }
      })
      .filter(element => element !== undefined);
    set(() => ({ selectedKinds: newKinds }));
  },
  // Set work order statuses
  setWorkOrderStatuses: statuses =>
    set(() => ({ workOrderStatuses: statuses })),
  // Set qbSelection
  setQbSelectionActive: bool => set({ qbSelectionActive: bool }),
  // Set loading state
  setLoading: bool => set({ isLoading: bool }),
  // Set page state
  setPageState: state => set({ pageState: state }),
  // Set items array
  setItems: items => set({ items: items }),
  setAdvancedSearchActive: bool => set({ advancedSearchActive: bool }),
  // Set query
  setQuery: query => set({ query: query }),
  // Set next cursor
  setNextCursor: nextCursor => set(state => ({ cursor: nextCursor })),

  // Set fireSearch
  setFireSearch: () => set(state => ({ fireSearch: state.fireSearch + 1 })),

  addSelectedItem: item =>
    set(state => ({ selectedItems: [...state.selectedItems, item] })),
  removeSelectedItem: item =>
    set(state => ({
      selectedItems: state.selectedItems.filter(i => i.id !== item.id)
    })),
  setSelectedItems: items => set({ selectedItems: items })
}));

export default useDashboardStore;
